import { Modal } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import { FC, useContext } from 'react';

import { ApplyFormContext } from '~/contexts/ApplyFormContext/ApplyFormContext';

import { ApplyCloseButton } from '../ApplyCloseButton';
import { ApplyCloseButtonProps } from '../types';

type ApplyStartFooterProps = ApplyCloseButtonProps;

export const ApplyStartFooter: FC<ApplyStartFooterProps> = ({ onClose }) => {
  const { loading } = useContext(ApplyFormContext);
  const { t } = useTranslation('all');

  return (
    <Modal.Footer>
      <Modal.FooterButton disabled={loading} size="l" type="submit" form="apply_start_form">
        {t('apply.modal_action_apply')}
      </Modal.FooterButton>
      <ApplyCloseButton disabled={loading} onClose={onClose} />
    </Modal.Footer>
  );
};
